<template>
  <div class="box">
<!--    <img class="lb1" src="../images/lb1.jpg">-->
    <div class="l_nav">
      <ul>
        <li>当前位置：</li>
        <li @click="goIndex"><a href="javascript:;">首页 </a></li>
        <template v-if="params.name">
          <li><span> &nbsp; > &nbsp; </span></li>
          <li @click="goBack"><a href="javascript:;">{{params.name}}</a></li>
        </template>
        <li><span> &nbsp; > &nbsp; </span></li>
        <li>{{detailsData.journalisTitle}}</li>
      </ul>
    </div>
    <div class="content">
      <div class="title">{{detailsData.journalisTitle}}</div>
      <div class="title_m">
        <span>信息来源：{{detailsData.message}}</span>
        <span>发布日期：{{toDate(detailsData.createTime, 'yyyy-MM-dd')}}</span>
      </div>
      <div class="ql-editor" v-html="text"></div>
    </div>
  </div>
</template>
<script>
import { websiteListQueryId,getTextarea } from "@/api/template";
export default {
  name: "Details",
  components: { },
  data() {
    return {
      detailsData: '',
      params: '',
      text: ''
    };
  },
  created(){
    this.params = JSON.parse(window.sessionStorage.getItem('detailsValue'))
    this.init()
  },
  methods: {
    init(){
      websiteListQueryId(this.params.id).then(res =>{
        this.detailsData = res.data
        this.getTextareaFun(res.data.journalisContent)
      })
    },
    goBack(){
      this.$router.go(-1)
    },
    goIndex(){
      this.$router.push("/template-sixth");
    },
    getTextareaFun(journalisContent){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        this.text = res.data.dataHtml
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.box{
  background: #fff;
  width: 1200px;
  margin: 0 auto;
}
.lb1{
  display: block;
  width: 1200px;
  margin: 0 auto 20px;
}
.l_nav{
  width: 1160px;
  margin: 0 auto;
  height: 40px;
  //background-color: #f7f7f7;
  padding: 20px;
  ul{
    width: 1200px;
    margin: 0 auto;
    li{
      float: left;
      line-height: 40px;
      span{
        padding: 0 10px;
      }
    }
  }
}
.content{
  width: 1200px;
  min-height: 560px;
  margin: 0 auto;
  //padding: 20px;
  //background: #fff;
  .title{
    color: #1e3c5a;
    font-size: 26px;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
  }
  .title_m{
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 20px;
    //padding: 20px;
    font-size: 14px;
    span{
      margin-right: 30px;
    }
  }
  .ql-editor{
    padding: 20px;
    line-height: 30px;
  }
}
.fenge{
  background-color: #D2D2D2;
  height: 30px;
  margin-top: 20px;
  clear: both;
}
.footer{
  background-color: #3e4753;
  padding: 20px 0;
  clear: both;
  .footerBox{
    width: 1200px;
    margin: 0 auto;
    color: #d2d2d2;
    font-size: 16px;
    line-height: 30px;
    span{
      margin-right: 30px;
    }
  }
}
</style>
<style>
.ql-align-center{
  text-align: center!important;
}
</style>
